import React from "react"

export const TitleUnderlineNavbarVerticalOne = ({ underline }) => (
  <span>
    {underline}
    <svg width="249" height="11" viewBox="0 0 249 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M46.4195 9.27649C40.4523 8.50268 3.77456 10.4042 1.15768 10.2485C-1.16401 9.98295 0.475559 4.11333 2.28096 3.19754C2.92106 2.87204 3.75601 2.78784 5.75152 2.88389C7.50169 2.96725 19.5627 2.54457 48.7409 1.61309C49.3125 1.78413 50.7562 1.15088 51.0985 1.36518C51.3595 1.48963 55.5974 1.45431 61.8971 1.36467C68.9659 1.26303 88.2092 0.901332 97.6997 0.764346C111.706 0.554665 106.438 0.583641 122.473 0.419955C125.827 0.384774 123.207 0.748335 127.151 0.60511C135.563 0.305135 153.951 0.630562 160.318 0.357077C165.424 0.137253 166.536 0.127347 171.443 0.375333C175.369 0.572605 203.068 0.665024 206.195 0.326921C206.949 0.225468 207.521 0.279241 207.504 0.407562C207.831 0.420744 226.267 0.874899 226.349 0.98149C226.479 1.11072 227.035 1.18673 227.575 1.1063C228.557 0.96717 248.6 0.973346 248.876 1.35474C249.655 2.45396 246.24 6.93345 243.829 7.99627C241.302 9.10863 228.877 7.48541 218.206 7.99482C218.206 7.99482 215.196 7.94836 210.681 7.8703C166.591 7.15208 168.095 7.46844 159.683 7.774C158.491 7.3758 153.986 8.30846 151.192 7.62679C150.032 7.34604 141.835 7.29556 140.001 7.56345C139.608 7.62245 136.696 7.62686 132.458 7.61751C128.237 7.60827 122.691 7.5797 117.079 7.60097C114.264 7.61156 111.434 7.61646 108.75 7.63903C106.051 7.6559 103.482 7.7015 101.207 7.74892C96.6417 7.84364 93.2377 7.97344 92.1893 8.17917C83.865 7.58069 54.3733 8.93461 46.4195 9.27649Z" fill="#FFBA00" />
    </svg>
  </span>
)

export const TitleUnderlineNavbarVerticalTwo = ({ underline }) => (
  <span>
    {underline}
    <svg width="214" height="11" viewBox="0 0 214 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M40.4977 9.29538C35.4161 8.52703 4.17576 10.4621 1.94709 10.3087C-0.0300609 10.0453 1.37173 4.17421 2.91024 3.25678C3.45571 2.9307 4.16692 2.84573 5.86642 2.93996C7.35696 3.02172 17.6298 2.58803 42.4819 1.62991C42.9685 1.80042 44.1987 1.16586 44.4901 1.37984C44.7123 1.50405 48.3217 1.46486 53.6873 1.36948C59.7079 1.26138 76.0978 0.882106 84.181 0.736454C96.1108 0.513982 91.6235 0.54777 105.281 0.36944C108.138 0.331197 105.906 0.697148 109.265 0.550322C116.43 0.242667 132.091 0.551299 137.513 0.272001C141.862 0.0475147 142.81 0.0365929 146.989 0.280096C150.332 0.473782 173.924 0.540904 176.588 0.199947C177.229 0.0978063 177.717 0.151056 177.702 0.279392C177.981 0.292276 193.683 0.72959 193.752 0.836107C193.862 0.965215 194.336 1.04071 194.796 0.959793C195.633 0.819769 212.704 0.807641 212.938 1.18878C213.6 2.28728 210.688 6.76987 208.633 7.83488C206.48 8.94954 195.9 7.33768 186.811 7.85683C186.811 7.85683 184.247 7.81313 180.401 7.73919C142.85 7.06123 144.131 7.37623 136.966 7.68946C135.951 7.29235 132.113 8.22913 129.735 7.55001C128.747 7.27032 121.766 7.22732 120.203 7.49689C119.869 7.55625 117.388 7.56331 113.779 7.55784C110.184 7.55245 105.46 7.52894 100.68 7.55534C98.2831 7.5685 95.8722 7.57599 93.5867 7.601C91.2873 7.62034 89.0992 7.66829 87.162 7.71778C83.2735 7.81668 80.3742 7.94958 79.481 8.15626C72.3917 7.5654 47.2723 8.94624 40.4977 9.29538Z" fill="#FFBA00" />
    </svg>
  </span>
)
