/* eslint-disable no-undef */
import React from "react"
import TemplatePageChatbostWhasapp from "@components/pageFunctionalities/pageChatbotsWhatsapp/template"
import { Helmet } from "react-helmet"

const IndexPage = ({ location }) => (
  <div className="fnd">
     <Helmet htmlAttributes={{ lang: 'es' }}>
      <title>Chatbots con IA para WhatsApp: Precios actualizados en el 2024 [Antes Securitec]</title>
      <link
        rel="icon"
        type="image/png"
        href={`https://beexcc.com/Beex.png`}
      />
      <link
        rel="canonical"
        href={`https://beexcc.com/whatsapp-business-api/chatbots-precio/`}
      />
      <meta name="robots" content="index, follow"></meta>
      <meta charSet="utf-8" />
      <meta
        name="description"
        content="¿Quieres tener chatbots para WhatsApp y tienes dudas de los precios? Aquí te ayudamos a resolver tus dudas para tu mejor decisión comercial."
      />
      <meta property="og:locale" content="es_ES" />
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content="Chatbots con IA para WhatsApp: Precios actualizados en el 2024"
      />
      <meta
        property="og:url"
        content={`https://beexcc.com/whatsapp-business-api/chatbots-precio/`}
      />
      <meta
        property="og:image"
        content={`https://beexcc.com/open-Beex.png`}
      />
      <meta
        property="og:description"
        content="¿Quieres tener chatbots para WhatsApp y tienes dudas de los precios? Aquí te ayudamos a resolver tus dudas para tu mejor decisión comercial."
      />
    </Helmet>
    <TemplatePageChatbostWhasapp location={location}/>
  </div>
)

export default IndexPage
