import React from "react"

const titleUnderline = ({ underline }) => (
  <span>
    {underline}
    <svg
      style={{ bottom: "-2px" }}
      width="160" height="10" viewBox="0 0 160 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M29.6749 8.72465C25.9758 7.97829 1.92815 10.1013 0.55031 9.99622C-0.908976 9.75296 0.855743 3.89821 2.1182 2.96941C2.89196 2.4055 2.83766 2.81461 9.50965 2.4055C9.50965 2.4055 19.5685 1.80842 31.9351 1.11182C31.9351 1.11182 51.8018 0.210661 79.1549 0.000575625C81.3065 -0.0160101 79.6029 0.332289 82.1346 0.210661C87.5306 -0.0381242 99.2727 0.431804 103.359 0.232775C106.637 0.072447 107.343 0.0613902 110.472 0.365461C112.623 0.575547 130.678 1.09523 132.68 0.780103C133.169 0.702703 133.522 0.75246 133.515 0.879617C133.658 0.890674 145.488 1.64809 145.542 1.76419C145.624 1.89687 145.983 1.9798 146.33 1.91346C146.968 1.7863 159.762 2.17883 159.932 2.56583C160.42 3.67154 158.187 8.03911 156.619 9.05083C154.997 10.0957 147.131 8.26025 140.18 8.57538C107.764 6.94445 108.015 7.35909 102.599 7.57471C101.852 7.17112 98.9062 8.03911 97.155 7.34804C96.422 7.05502 91.155 6.94445 89.974 7.19324C89.7229 7.24852 87.8428 7.23746 85.1278 7.19876C83.7703 7.18218 82.2025 7.15454 80.526 7.13242C78.2861 7.09925 61.3381 6.94998 59.2136 7.59129C53.4986 6.94998 35.1116 8.33765 29.6749 8.72465Z" fill="#FFBA00"/>
      </svg>
  </span>
)

export default titleUnderline
