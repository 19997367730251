import React from "react"

export const TitleUnderlineRightOne = ({ underline }) => (
  <span>
    {underline}
    <svg
      style={{ bottom: "-2px" }}
      width="282" height="9" viewBox="0 0 282 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M52.2334 8.33102C46.4354 7.42759 3.92446 8.25375 0.825925 7.99818C-1.70924 7.64156 2.18742 1.47805 4.51132 0.568684C5.3329 0.24773 6.29528 0.18235 8.50181 0.354714C10.2623 0.485473 22.3748 0.467639 34.0647 0.402259C45.7547 0.33688 57.0221 0.259614 57.0221 0.259614C57.6089 0.455753 59.4164 -0.180211 59.745 0.0515895C60.2614 0.324995 78.7587 0.372544 97.9133 0.372544C117.068 0.372544 136.88 0.354711 139.978 0.360654C143.757 0.366598 140.729 0.717274 145.189 0.610289C154.673 0.384432 175.283 0.883691 182.466 0.616229C188.24 0.40226 189.461 0.414147 194.954 0.681609C200.54 0.955015 230.493 0.996621 233.967 0.622174C234.812 0.515189 235.446 0.562737 235.446 0.699439C235.845 0.71727 256.502 1.11549 256.572 1.21653C256.713 1.35324 257.347 1.4305 257.957 1.34135C259.084 1.21059 281.595 1.01445 281.877 1.41862C282.746 2.57762 278.825 7.38004 276.079 8.5331C273.192 9.75154 258.896 8.12894 247.065 8.73518C182.536 8.00412 195.376 8.06356 180.799 8.50338C179.508 8.07544 174.297 9.04425 171.198 8.29536C169.931 7.98629 160.659 7.86742 158.546 8.14677C156.222 8.45584 109.768 7.30277 104.322 8.17054C95.6833 7.40976 60.4022 8.19432 52.2334 8.33102Z" fill="#FFBA00" />
    </svg>
  </span>
)

export const TitleUnderlineRightTwo = ({ underline }) => (
  <span>
    {underline}
    <svg
      style={{ bottom: "-2px" }}
      width="143" height="9" viewBox="0 0 143 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M26.4871 8.33102C23.547 7.42759 1.99006 8.25375 0.41882 7.99818C-0.866742 7.64156 1.10922 1.47805 2.28765 0.568684C2.70427 0.24773 3.19229 0.18235 4.3112 0.354714C5.20395 0.485473 11.3461 0.467639 17.274 0.402259C23.2018 0.33688 28.9154 0.259614 28.9154 0.259614C29.213 0.455753 30.1296 -0.180211 30.2962 0.0515895C30.5581 0.324995 39.9379 0.372544 49.651 0.372544C59.3642 0.372544 69.4106 0.354711 70.9819 0.360654C72.8983 0.366598 71.3628 0.717274 73.6244 0.610289C78.4334 0.384432 88.8845 0.883691 92.5269 0.616229C95.4552 0.40226 96.0741 0.414147 98.8595 0.681609C101.693 0.955015 116.881 0.996621 118.643 0.622174C119.071 0.515189 119.393 0.562737 119.393 0.699439C119.595 0.71727 130.07 1.11549 130.106 1.21653C130.177 1.35324 130.499 1.4305 130.808 1.34135C131.379 1.21059 142.795 1.01445 142.938 1.41862C143.378 2.57762 141.39 7.38004 139.997 8.5331C138.533 9.75154 131.284 8.12894 125.285 8.73518C92.5626 8.00412 99.0738 8.06356 91.6818 8.50338C91.0271 8.07544 88.3845 9.04425 86.8133 8.29536C86.1705 7.98629 81.4687 7.86742 80.3974 8.14677C79.219 8.45584 55.6623 7.30277 52.9007 8.17054C48.5202 7.40976 30.6295 8.19432 26.4871 8.33102Z" fill="#FFBA00" />
    </svg>
  </span>
)