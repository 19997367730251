import React from "react"

export const TitleUnderlineChatbotsWhatsappOne = ({ underline }) => (
  <span>
    {underline}
    <svg width="371" height="10" viewBox="0 0 371 10" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M68.853 9.64229C61.1994 8.64989 5.1308 9.52953 1.0566 9.2242C-2.26909 8.82233 3.07014 1.97124 6.16164 0.970521C7.24529 0.610685 8.50303 0.56225 11.3837 0.737731C15.9513 1.06464 75.027 0.658443 75.027 0.658443C75.8063 0.872784 78.1487 0.166959 78.5777 0.426346C79.9288 1.03282 175.681 0.551259 183.848 0.547054C188.798 0.544711 184.881 0.940816 190.708 0.796103C203.125 0.509376 230.186 0.922376 239.595 0.578735C247.125 0.294576 248.749 0.287099 255.975 0.563891C263.361 0.814798 302.633 0.629571 307.124 0.162757C308.222 0.0343977 309.066 0.0836669 309.065 0.235743C309.065 0.268803 336.747 0.479018 336.874 0.598484C337.081 0.751291 337.924 0.827 338.704 0.723964C340.154 0.570394 369.621 0.0992635 370.081 0.543899C371.525 1.81852 367.448 7.21279 364.022 8.51649C360.515 9.91247 341.29 8.26427 325.831 9.05599L253.863 8.88765L238.436 9.3621C236.671 8.89963 229.87 10.0261 225.765 9.19169C224.031 8.86158 211.774 8.77197 209.067 9.10624C206.71 9.36239 144.009 8.47302 137.575 9.36936C126.116 8.56187 79.6305 9.49524 68.853 9.64229Z" fill="#FFA700"/>
</svg>
  </span>
)

export const TitleUnderlineChatbotsWhatsappTwo = ({ underline }) => (
  <span>
    {underline}
    <svg width="299" height="10" viewBox="0 0 299 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M55.5888 10C49.4071 9.07169 4.14324 10.1081 0.853199 9.82831C-1.83281 9.45317 2.45967 2.84689 4.95291 1.87407C5.82682 1.52436 6.84211 1.47351 9.16827 1.63247C12.8567 1.93131 60.5495 1.33997 60.5495 1.33997C61.1793 1.54344 63.0685 0.856744 63.4155 1.10472C64.5079 1.68332 141.811 0.894891 148.404 0.8631C152.401 0.844025 149.239 1.23824 153.943 1.07928C163.967 0.761364 185.815 1.06657 193.411 0.704142C199.49 0.405302 200.801 0.392594 206.635 0.63421C212.598 0.850392 244.304 0.538832 247.928 0.074676C248.815 -0.0524901 249.496 -0.00797814 249.496 0.138263C249.496 0.170054 271.845 0.278142 271.948 0.392591C272.115 0.538832 272.796 0.608769 273.426 0.507036C274.595 0.354437 298.384 -0.198737 298.757 0.227269C299.926 1.44806 296.649 6.64915 293.886 7.91446C291.058 9.26878 275.533 7.74915 263.054 8.56302L204.952 8.64568L192.498 9.15434C191.072 8.71561 185.584 9.82196 182.268 9.03353C180.868 8.72197 170.972 8.67746 168.787 9.00809C166.885 9.26242 116.262 8.62023 111.07 9.50403C101.816 8.76647 64.2894 9.82197 55.5888 10Z" fill="#FFBA00" />
    </svg>
  </span>
)